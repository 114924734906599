import { createRouter, createWebHashHistory } from "vue-router";
import ls from "local-storage";
import Store from "../store/index";

const routes = [
  {
    path: "/",
    name: "layout",
    desc: "初始化",
    redirect: "/index"
  },
  {
    path: "/index",
    name: "index",
    refresh: true,
    desc: "飞逸美选",
    component: () => import("../views/Index/index.vue")
  },
  {
    path: "/billDetail",
    name: "billDetail",
    desc: "账单详情",
    component: () => import("../views/BillDetail/index.vue")
  },
  {
    path: "/bill",
    name: "bill",
    refresh: true,
    desc: "账单",
    component: () => import("../views/Bill/index.vue")
  },
  {
    path: "/loanProtocol",
    name: "LoanProtocol",
    desc: "协议",
    component: () => import("../views/LoanProtocol/index.vue")
  },
  {
    path: "/pay",
    name: "pay",
    desc: "支付",
    component: () => import("../views/pay/index.vue")
  },
  {
    path: "/payResult",
    name: "payResult",
    desc: "支付成功",
    component: () => import("../views/payResult/index.vue")
  },

  {
    path: "/auth",
    name: "auth",
    desc: "实名认证",
    component: () => import("../views/auth/index.vue")
  },
  {
    path: "/baseInfo",
    name: "BaseInfo",
    desc: "基础信息",
    component: () => import("../views/baseInfo/index.vue")
  },
  {
    path: "/ImmediateStaging",
    name: "ImmediateStaging",
    desc: "借款意向",
    component: () => import("../views/ImmediateStaging/index.vue")
  },
  {
    path: "/infoDetail",
    name: "infoDetail",
    desc: "订单详情",
    component: () => import("../views/infoDetail/index.vue")
  },
  {
    path: "/loan",
    name: "todivisionmoney",
    desc: "立即分期",
    component: () => import("../views/loan/index.vue")
  },
  {
    path: "/infoNavigation",
    name: "infoNavigation",
    refresh: true,
    desc: "授权资料",
    component: () => import("../views/infoNavigation/index.vue")
  },
  {
    path: "/face",
    name: "face",
    desc: "活体回调",
    component: () => import("../views/face/index.vue")
  },
  {
    path: "/addAccount",
    name: "addAccount",
    desc: "添加银行卡",
    component: () => import("../views/addAccount/index.vue")
  },
  {
    path: "/mine",
    name: "myself",
    desc: "我的",
    component: () => import("../views/mine/index.vue")
  },
  {
    path: "/MyBank",
    name: "MyBank",
    desc: "我的银行卡",
    component: () => import("../views/MyBank/index.vue")
  },
  {
    path: "/Withhold",
    name: "Withhold",
    desc: "代扣设置",
    component: () => import("../views/Withhold/index.vue")
  },
  {
    path: "/repayment",
    name: "repayment",
    desc: "确认还款金额",
    component: () => import("../views/Repayment/index.vue")
  },
  {
    path: "/authSuccess",
    name: "authSuccess",
    desc: "提交成功",
    component: () => import("../views/authSuccess/index.vue")
  },
  {
    path: "/uploadImg",
    name: "uploadImg",
    desc: "资料上传",
    component: () => import("../views/uploadImg/index.vue")
  },
  {
    path: "/upload",
    name: "upload",
    desc: "上传截图",
    component: () => import("../views/upload/index.vue")
  },
  {
    path: "/loanSuccess",
    name: "loanSuccess",
    desc: "借款成功",
    component: () => import("../views/loanSuccess/index.vue")
  },
  {
    path: "/login",
    name: "login",
    desc: "登录",
    component: () => import("../views/login/index.vue")
  }
];
// 实例化路由
const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
  routes
});

// 路由导航守卫
router.beforeEach((to, from, next) => {
  const { path, query } = to;
  let { mobile, env } = JSON.parse(query.params || "{}");
  if (env == "APP") {
    localStorage.clear();
  }
  if (path === "/login") return next();
  if (path === "/baseInfo") return next();
  let r = to;
  routes.forEach(item => {
    if (item.path === path) {
      r = Object.assign({}, to, item);
      document.title = item.desc;
    }
  });

  Store.dispatch("route/SetRoute", r);
  Store.dispatch("route/SetTitle", r.desc);
  Store.dispatch("route/SetRefresh", false);
  if (r.refresh) Store.dispatch("route/SetRefresh", true);

  switch (path) {
    case "/index":
    case "/mine":
    case "/bill":
      Store.dispatch("route/SetTabBar", true);
      break;
    default:
      Store.dispatch("route/SetTabBar", false);
      break;
  }

  const isLogin = ls.get("userId");
  if (!isLogin && path == "/index" && env == "APP") {
    console.log("APP登录");
    Store.dispatch("user/appLogin", mobile);
    return;
  }
  if (!isLogin) {
    Store.dispatch("route/SetTabBar", false);
    return next({
      path: "/login"
    });
  }
  next();
});

export default router;
